import React, { useState } from "react";
import 'animate.css';
export const AboutUs = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    return (
                
        <section className="AboutUs" id="AboutUs">
            <div className='container'>
                <h2>AboutUs</h2>
                </div>
                <h5>Our Story</h5>
                <p id='story'><strong>Baazarse1000</strong> began as a visionary project in 2022 and has since become a pioneering platform in the realm of long-term investment strategy. 
                
                     Our founder's personal journey from a curious investor to a fintech innovator highlighted the crucial need for a tool that transforms complex investment data into actionable knowledge. This led to the creation of Baazarse1000, where we make sophisticated investing simple and accessible.
                  </p>
                  {isExpanded && (
                  <span>  
                 <h5>Our Mission</h5>
                 <p>At Baazarse1000, our mission is straightforward: to empower individuals globally with the knowledge and tools necessary for successful long-term investing. We strive to provide clear, consistent, and unbiased insights into financial analysts, boutique firms, and market trends. By offering investment knowledge in native languages, we ensure that investors from diverse backgrounds can effectively access and leverage our resources. Our platform is dedicated to helping users navigate the noisy financial landscape with confidence and clarity, equipping every investor to make informed decisions and fostering a global community of informed and successful investors.</p>
                 <h5>Our Product</h5>
                 <ul>
                    <li><strong>Comprehensive Analyst Overviews:</strong> Gain insights into financial analysts, including detailed stock recommendations, historical returns, and metrics that evaluate their performance over time.</li>
                    <li><strong>In-Depth Firm Analysis:</strong> We provide balanced coverage on boutique firms, incorporating findings from regulatory bodies like SEBI, NSE, and BSE—some of which may not be readily available to the public.</li>
                    <li><strong>Advanced Data Visualizations:</strong> Our platform features dynamic visualizations and charting capabilities that not only track historical performance but also project future trends of analyst recommendations.</li>
                    <li><strong>Multi-Lingual Support:</strong> Understanding the diversity of our users, we offer multi-lingual support to cater to investors from various geographies, including those in tier 2 and tier 3 cities.</li>
                    <li><strong>Customizable Investment Baskets:</strong> Customize your investment strategies with baskets tailored to different parameters such as risk tolerance and investment appetite.</li>
                </ul>
                <h5>Why Choose Us</h5>
                <ul>
                    <li><strong>Intuitive Design:</strong> Our platform is crafted for ease of use, ensuring all investors, regardless of their experience level, can navigate and benefit from our tools.</li>
                    <li><strong>Rigorous Data Integrity:</strong> Access meticulously curated data that brings transparency to the forefront of your investment decisions.</li>
                    <li><strong>Commitment to Education:</strong> Continuously learn and improve with our educational resources that help you think like a long-term investor.</li>
                    <li><strong>Community Driven:</strong> Become part of a community that values sustainable and informed investing.</li>
                </ul>
                <h5>Join Our Community</h5>
                <p>Start your investment journey with Baazarse1000 and experience a platform that not only guides you through the world of investing but also educates and prepares you for future financial success. Let's invest wisely and grow together.</p>
                </span>
                   )}
               
                <button id="read-more-btn" onClick={toggleReadMore}>
                {isExpanded ? "Read Less" : "Read More"}</button>
        </section>
        
      )
    
  };
  
  export default AboutUs;